/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/swiper/modules/pagination/pagination.scss";
@import "../node_modules/swiper/modules/navigation/navigation.scss";

@font-face {
    font-family: 'RohnRounded-Medium';
    src: url('./assets/fonts/RohnRounded-Medium.ttf')
  }

  @font-face {
    font-family: 'RohnRounded-Heavy';
    src: url('./assets/fonts/RohnRounded-Heavy.ttf')
  }

  @font-face {
    font-family: 'RohnRounded-Bold';
    src: url('./assets/fonts/RohnRounded-Bold.ttf')
  }

  @font-face {
    font-family: 'RohnRounded-Black';
    src: url('./assets/fonts/RohnRounded-Black.ttf')
  }
  
button {
    border-top-style: inherit !important;
    border-right-style: inherit !important;
    border-bottom-style: inherit !important;
    border-left-style: inherit !important;
}

ion-content {
    --background: var(--ion-color-background-content) !important;
    --padding-top: var(--ion-safe-area-top) !important;
    --padding-bottom: var(--ion-safe-area-bottom) !important;
}

ion-content.content-with-white-background {
    --background: white !important;
}


input {
  color-scheme: dark;
}

.content-without-padding {
    --padding-top: 0px !important;
    --padding-bottom: 0px !important;
}

.datatable-center {
  font-size:10px!important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;;
}

.datatable-center2 {
  font-size:10px!important;
    display: flex !important;
   
}

.not-selected {
  background-color: #f0f0e6 !important;
}



.selected {
  min-width: 58px !important;

 font-weight: 600;

  background-color: rgb(230 230 214 / 1)!important
}

.stats-block .datatable-header {
  background-color: #f0f0e6 !important;
}


.stats-block .datatable-body .datatable-row-wrapper {
  background-color: #f0f0e6 !important;
}

.highlight {
  background-color: lightgray;
}

.borderTop {
  border-top-color: black;
  border-width: 0.5px;
}

.borderBottom {
  border-bottom-color: black;
  border-width: 0.5px;
}

.highlighted-widget {
  box-shadow: 0 0 0 10px yellow; /* Adjust the color and size as desired */
}
.ck-content{
//padding:20px!important;
}


.dashboard-table .datatable-body .datatable-body-row .datatable-body-cell {
  padding:5px!important;
  }
// .cdk-drag-preview{
//   max-height:100px!important;
// }

datatable-scroller {
    width: 100% !important;
  }
  
  datatable-footer {
    height: 55px !important;
    overflow-y: hidden !important;
    background-color: #f0f0e6 !important;
  }
  
ion-modal.auto-height {
    &.bottom {
        align-items: flex-end !important;
    }

    --height: auto !important;

    .ion-page {
        position: relative !important;
        display: block !important;
        contain: content !important;

        .inner-content {
            max-height: 80vh !important;
            overflow: auto;
            padding: 10px !important;
        }
    }
}

ion-modal.lg-modal {
   
    --height: 80% !important;
    --width: 80% !important;

}

ion-menu {
    width: 280px !important;
}

ion-toggle{

    --handle-width: 22px !important;
    //   border-radius: 6px !important;
      width: 47px !important;
      height: 22px !important;
      top: 4px !important;

    
}

ngx-datatable.listfixed{
 height:calc(100vh - 200px);
}

.my-segment {
  width: 150px; /* set width of segment container */
}

.my-segment ion-segment-button {
  flex-basis: 0; /* allow buttons to fill available space */
  width: 40px; /* set width of buttons */
}
.template-modal{
--width:100vh !important;
--height:100% !important;
}

.template-modal2{
  --width:100vh !important;
  --height:400px!important;
  }
  .template-modal3{
    --width:100vh !important;
    --height:calc( 100vh - 100px)!important;
    }

.selectBB{
  --border-width:0.5px;
  --border-color:green!important;
  --border-radius:6px!important;
  
 }

 .ma{
  font-size: 12px!important;
 }

ion-modal.bottom-modal{
    --height: 15rem;
    --border-radius: 10px 10px 0px 0px;
    align-items: flex-end;
}

ion-modal.lg-bottom-modal{
    min-height: 30rem !important ;
    --height: 38rem;
    --border-radius: 10px 10px 0px 0px;
    align-items: flex-end;

    .inner-content {
        max-height: 80vh !important;
        overflow: auto;
        padding: 10px !important;
    }
}

ion-input input[type='text']:focus {
   
    --tw-ring-color: none !important;
    box-shadow: none !important; 
    border-color: none !important;
}


.ck-editor__editable {
    min-height: 200px;
  }

  .ck ol li {
    margin-left:40px!important;
  }

  .ck ul li {
    margin-left:40px!important;
  }

  //DRAGULA CSS
  /* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
  }
  /* high-performance display:none; helper */
  .gu-hide {
    left: -9999px !important;
  }
  /* added to mirrorContainer (default = body) while dragging */
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  /* added to the source element while its mirror is dragged */
  .gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
  }

  .dashboard-table .datatable-footer .datatable-pager .pager li:first-child {
    display: none;
  } 
  
  .dashboard-table .datatable-footer .datatable-pager .pager li:last-child {
    display: none;
  } 